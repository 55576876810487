.not_found_container,
.not_access_container,
.restricted_container {
    display: flex;
    align-items: center;
    gap: 28px;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.not_found_content,
.not_access_content,
.restricted_content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 500px;
}

.not_found_logo,
.not_access_logo,
.restricted_logo {
    width: 258px;
    height: 258px;
}

.code_error {
    font-weight: 700;
    font-size: 64px;
    margin: 0;
    line-height: normal;
    margin-bottom: 8px;
}

.not_found_title,
.not_access_title,
.restricted_ title {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
}

.not_found_content_title,
.not_access_content_title,
.restricted_content_title {
    font-size: 14px;
}
